import React, { Fragment, lazy, Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import MinimalLayout from "./layouts/Minimal";
import MainLayout from "./layouts/Main";
import LoadingScreen from "./components/LoadingScreen";
import AuthGuard from "./components/AuthGuard";
import GuestGuard from "./components/GuestGuard";
import { useStore } from "./contexts/JWTAuthContext";
import { checkPermission } from "./utils/acl/aclRouter";
import { Dashboard } from "./views";
import LoginView from "./views/LoginView";
import { Helmet } from "react-helmet";

export const RenderRoutes = ({ routes }) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { user, isAuthenticated } = useStore();
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        {routes.map((route, i) => {
          const Guard = route.guard || Fragment;
          const Layout = route.layout || Fragment;
          const Component = route.component;

          return (
            <Route
              key={i}
              path={route.path}
              exact={route.exact}
              render={(props) => (
                <Guard>
                  <Layout>
                    <Helmet>
                      <title>{route.title}</title>
                    </Helmet>
                    {route.routes ? (
                      <RenderRoutes routes={route.routes} />
                    ) : user &&
                      checkPermission(
                        route.path,
                        user.roles,
                        user.admin_groups
                      ) ? (
                      <Component {...props} />
                    ) : !user && !isAuthenticated ? (
                      <LoginView />
                    ) : (
                      <Dashboard />
                    )}
                  </Layout>
                </Guard>
              )}
            />
          );
        })}
      </Switch>
    </Suspense>
  );
};

const routes = [
  {
    exact: true,
    layout: MinimalLayout,
    path: "/404",
    component: lazy(() => import("./views/NotFound")),
  },
  {
    exact: true,
    layout: MinimalLayout,
    guard: GuestGuard,
    path: "/login",
    component: lazy(() => import("./views/LoginView")),
    title: "Takiacademy|Admin",
  },
  {
    path: "/",
    guard: AuthGuard,
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: "/",
        component: lazy(() => import("./views/Dashboard")),
        title: "Takiacademy|Admin",
      },
      {
        exact: true,
        path: "/account",
        component: lazy(() => import("./views/Account")),
        title: "Account|Takiacademy",
      },
      {
        exact: true,
        path: "/users/students",
        component: lazy(() => import("./views/User/UserList")),
        title: "Students|Takiacademy",
      },
      {
        exact: true,
        path: "/users/students/create",
        component: lazy(() => import("./views/User/UserCreate")),
        title: "Students-Create|Takiacademy",
      },
      {
        exact: true,
        path: "/users/students/edit/:id",
        component: lazy(() => import("./views/User/UserEdit")),
        title: "Students-Edit|Takiacademy",
      },
      {
        exact: true,
        path: "/users/students/view/:id",
        component: lazy(() => import("./views/User/UserShow")),
        title: "Students-View|Takiacademy",
      },
      {
        exact: true,
        path: "/users/parent-users",
        component: lazy(() => import("./views/Parent/ParentList")),
        title: "Parents|Takiacademy",
      },
      {
        exact: true,
        path: "/users/parent-users/create",
        component: lazy(() => import("./views/Parent/ParentCreate")),
        title: "Parents-Create|Takiacademy",
      },
      {
        exact: true,
        path: "/users/parent-users/edit/:id",
        component: lazy(() => import("./views/Parent/ParentEdit")),
        title: "Parents-Edit|Takiacademy",
      },
      {
        exact: true,
        path: "/users/parent-users/view/:id",
        component: lazy(() => import("./views/Parent/ParentShow")),
        title: "Parents-View|Takiacademy",
      },
      {
        exact: true,
        path: "/users/parent-user-children",
        component: lazy(() => import("./views/Children/ChildrenList")),
        title: "Approval-Requests|Takiacademy",
      },
      {
        exact: true,
        path: "/users/parent-user-children/create",
        component: lazy(() => import("./views/Children/ChildrenCreate")),
        title: "Approval-Requests-Create|Takiacademy",
      },
      {
        exact: true,
        path: "/users/parent-user-children/edit/:id",
        component: lazy(() => import("./views/Children/ChildrenEdit")),
        title: "Approval-Requests-Edit|Takiacademy",
      },
      {
        exact: true,
        path: "/users/parent-user-children/view/:id",
        component: lazy(() => import("./views/Children/ChildrenShow")),
        title: "Approval-Requests-View|Takiacademy",
      },
      {
        exact: true,
        path: "/settings/user-types",
        component: lazy(() => import("./views/UserType/UserTypeList")),
        title: "Settings|User-Types|Takiacademy",
      },
      {
        exact: true,
        path: "/settings/user-types/create",
        component: lazy(() => import("./views/UserType/UserTypeCreate")),
        title: "Settings|User-Types-Create|Takiacademy",
      },
      {
        exact: true,
        path: "/settings/user-types/edit/:id",
        component: lazy(() => import("./views/UserType/UserTypeEdit")),
        title: "Settings|User-Types-Edit|Takiacademy",
      },
      {
        exact: true,
        path: "/users/business",
        component: lazy(() => import("./views/Business/BusinessList")),
        title: "Business|Takiacademy",
      },
      {
        exact: true,
        path: "/users/business/create",
        component: lazy(() => import("./views/Business/BusinessCreate")),
        title: "Business-Create|Takiacademy",
      },
      {
        exact: true,
        path: "/users/business/edit/:id",
        component: lazy(() => import("./views/Business/BusinessEdit")),
        title: "Business-Edit|Takiacademy",
      },
      {
        exact: true,
        path: "/users/business/view/:id",
        component: lazy(() => import("./views/Business/BusinessShow")),
        title: "Business-View|Takiacademy",
      },

      {
        exact: true,
        path: "/users/user-borne",
        component: lazy(() => import("./views/UserBorne/UserBorneList")),
        title: "UserBorne|Takiacademy",
      },
      {
        exact: true,
        path: "/users/user-borne/edit/:id",
        component: lazy(() => import("./views/UserBorne/UserBorneEdit")),
        title: "UserBorne-Edit|Takiacademy",
      },
      {
        exact: true,
        path: "/users/user-borne/view/:id",
        component: lazy(() => import("./views/UserBorne/UserBorneShow")),
        title: "UserBorne-View|Takiacademy",
      },
      {
        exact: true,
        path: "/users/teachers",
        component: lazy(() => import("./views/Teacher/TeacherList")),
        title: "Teachers|Takiacademy",
      },
      {
        exact: true,
        path: "/users/teachers/create",
        component: lazy(() => import("./views/Teacher/TeacherCreate")),
        title: "Teachers-Create|Takiacademy",
      },
      {
        exact: true,
        path: "/users/teachers/edit/:id",
        component: lazy(() => import("./views/Teacher/TeacherEdit")),
        title: "Teachers-Edit|Takiacademy",
      },
      {
        exact: true,
        path: "/users/teachers/event-penalty/edit/:id",
        component: lazy(
          () => import("./views/Teacher/components/EventPenaltyForm")
        ),
        title: "Teachers-Edit-Penalty-Event|Takiacademy",
      },
      {
        exact: true,
        path: "/users/teachers/view/:id",
        component: lazy(() => import("./views/Teacher/TeacherShow")),
        title: "Teachers-View|Takiacademy",
      },

      {
        exact: true,
        path: "/contents/divisions",
        component: lazy(() => import("./views/Division/DivisionList")),
        title: "Contents|Divisions|Takiacademy",
      },
      {
        exact: true,
        path: "/contents/divisions/create",
        component: lazy(() => import("./views/Division/DivisionCreate")),
        title: "Contents|Divisions-Create|Takiacademy",
      },
      {
        exact: true,
        path: "/contents/divisions/edit/:id",
        component: lazy(() => import("./views/Division/DivisionEdit")),
        title: "Contents|Divisions-Edit|Takiacademy",
      },
      {
        exact: true,
        path: "/contents/chapters",
        component: lazy(() => import("./views/Chapter/ChapterList")),
        title: "TA|Contents-chapters",
      },
      {
        exact: true,
        path: "/contents/chapters/create",
        component: lazy(() => import("./views/Chapter/ChapterCreate")),
        title: "Contents|Chapters-Create|Takiacademy",
      },
      {
        exact: true,
        path: "/contents/chapters/edit/:id",
        component: lazy(() => import("./views/Chapter/ChapterEdit")),
        title: "Contents|Chapters-Edit|Takiacademy",
      },
      {
        exact: true,
        path: "/contents/chapters/view/:id",
        component: lazy(() => import("./views/Chapter/ChapterShow")),
        title: "Contents|Chapters-View|Takiacademy",
      },
      {
        exact: true,
        path: "/contents/subjects",
        component: lazy(() => import("./views/Subject/SubjectList")),
        title: "Contents|Subjects|Takiacademy",
      },
      {
        exact: true,
        path: "/contents/subjects/create",
        component: lazy(() => import("./views/Subject/SubjectCreate")),
        role: "subject_create",
        title: "Contents|Subjects-Create|Takiacademy",
      },
      {
        exact: true,
        path: "/contents/subjects/edit/:id",
        component: lazy(() => import("./views/Subject/SubjectEdit")),
        title: "Contents|Subjects-Edit|Takiacademy",
      },
      {
        exact: true,
        path: "/contents/domains",
        component: lazy(() => import("./views/Domain/DomainList")),
        title: "Contents|Domains|Takiacademy",
      },
      {
        exact: true,
        path: "/contents/domains/create",
        component: lazy(() => import("./views/Domain/DomainCreate")),
        role: "subject_create",
        title: "Contents|Domains-Create|Takiacademy",
      },
      {
        exact: true,
        path: "/contents/domains/edit/:id",
        component: lazy(() => import("./views/Domain/DomainEdit")),
        title: "Contents|Domains-Edit|Takiacademy",
      },
      {
        exact: true,
        path: "/contents/recordings",
        component: lazy(() => import("./views/Recordings/RecordingsList")),
        title: "Contents|Recordings|Takiacademy",
      },
      // {
      //   exact: true,
      //   path: "/settings/permissions",
      //   component: lazy(() =>
      //     import("./views/AdminPermission/AdminPermissionList")
      //   ),
      // },
      {
        exact: true,
        path: "/developer/logs",
        component: lazy(() => import("./views/Logs/logList")),
        title: "Developer-Logs|Takiacademy",
      },
      {
        exact: true,
        path: "/developer/audit",
        component: lazy(() => import("./views/auditDeveloper/components/Home")),
        title: "Developer-Audit|Takiacademy",
      },
      {
        exact: true,
        path: `/developer/audit/:entity`,
        component: lazy(
          () => import("./views/auditDeveloper/components/ListItems")
        ),
        title: "Developer-Audit|Takiacademy",
      },
      {
        exact: true,
        path: "/developer/switch-log",
        component: lazy(() => import("./views/SwitchLogs/SwitchLogsList")),
        title: "SwitchLogs|Takiacademy",
      },
      {
        exact: true,
        path: "/finance/payment-agreements",
        component: lazy(
          () => import("./views/PaymentAgreement/PaymentAgreementList")
        ),
        title: "Finance|Payment-Agreements|Takiacademy",
      },
      {
        exact: true,
        path: "/finance/payment-agreements/create",
        component: lazy(
          () => import("./views/PaymentAgreement/PaymentAgreementCreate")
        ),
        title: "Finance|Payment-Agreements-Create|Takiacademy",
      },
      {
        exact: true,
        path: "/finance/payment-agreements/edit/:id",
        component: lazy(
          () => import("./views/PaymentAgreement/PaymentAgreementEdit")
        ),
        title: "Finance|Payment-Agreements-Edit|Takiacademy",
      },
      {
        exact: true,
        path: "/finance/payment-agreements/view/:id",
        component: lazy(
          () => import("./views/PaymentAgreement/PaymentAgreementShow")
        ),
        role: "paymentagreements_show",
        title: "Finance|Payment-Agreements-View|Takiacademy",
      },
      {
        exact: true,
        path: "/finance/payment-agreement-slices",
        component: lazy(
          () =>
            import("./views/PaymentAgreementSlice/PaymentAgreementSliceList")
        ),
        title: "Finance|Payment-Agreement-Slices|Takiacademy",
      },
      {
        exact: true,
        path: "/finance/payment-agreement-slices/create",
        component: lazy(
          () =>
            import("./views/PaymentAgreementSlice/PaymentAgreementSliceCreate")
        ),
        title: "Finance|Payment-Agreement-Slices-Create|Takiacademy",
      },
      {
        exact: true,
        path: "/finance/payment-agreement-slices/edit/:id",
        component: lazy(
          () =>
            import("./views/PaymentAgreementSlice/PaymentAgreementSliceEdit")
        ),
        title: "Finance|Payment-Agreement-Slices-Edit|Takiacademy",
      },
      {
        exact: true,
        path: "/finance/payment-agreement-slices/view/:id",
        component: lazy(
          () =>
            import("./views/PaymentAgreementSlice/PaymentAgreementSliceShow")
        ),
        title: "Finance|Payment-Agreement-Slices-View|Takiacademy",
      },
      {
        exact: true,
        path: "/marketing/discount-codes",
        component: lazy(
          () => import("./views/DiscountCodes/DiscountCodesList")
        ),
        title: "Marketing|Discount-Codes|Takiacademy",
      },
      {
        exact: true,
        path: "/marketing/discount-offers",
        component: lazy(
          () => import("./views/SpecialOffersDiscount/DiscountList")
        ),
        title: "Marketing|Discount-Offers|Takiacademy",
      },
      {
        exact: true,
        path: "/marketing/discount-offers/edit/:id",
        component: lazy(
          () => import("./views/SpecialOffersDiscount/DiscountEdit")
        ),
        title: "Marketing|Discount-Offers-Edit|Takiacademy",
      },
      {
        exact: true,
        path: "/marketing/discount-offers/create",
        component: lazy(
          () => import("./views/SpecialOffersDiscount/DiscountCreate")
        ),
        title: "Marketing|Discount-Offers-Create|Takiacademy",
      },
      {
        exact: true,
        path: "/marketing/discount-offers/view/:id",
        component: lazy(
          () => import("./views/SpecialOffersDiscount/DiscountShow")
        ),
        title: "Marketing|Discount-Offers-View|Takiacademy",
      },
      {
        exact: true,
        path: "/marketing/discount-codes/edit/:id",
        component: lazy(
          () => import("./views/DiscountCodes/DiscountCodesEdit")
        ),
        title: "Marketing|Discount-Codes-Edit|Takiacademy",
      },
      {
        exact: true,
        path: "/marketing/discount-codes/create",
        component: lazy(
          () => import("./views/DiscountCodes/DiscountCodesCreate")
        ),
        title: "Marketing|Discount-Codes-Create|Takiacademy",
      },
      {
        exact: true,
        path: "/marketing/discount-codes/view/:id",
        component: lazy(
          () => import("./views/DiscountCodes/DiscountCodesShow")
        ),
        title: "Marketing|Discount-Codes-View|Takiacademy",
      },
      {
        exact: true,
        path: "/finance/affiliations",
        component: lazy(() => import("./views/Affiliation/AffiliationList")),
        title: "Finance|Affiliations|Takiacademy",
      },
      {
        exact: true,
        path: "/finance/affiliations/create",
        component: lazy(() => import("./views/Affiliation/AffiliationCreate")),
        title: "Finance|Affiliations-Create|Takiacademy",
      },
      {
        exact: true,
        path: "/finance/affiliations/edit/:id",
        component: lazy(() => import("./views/Affiliation/AffiliationEdit")),
        title: "Finance|Affiliations-Edit|Takiacademy",
      },
      {
        exact: true,
        path: "/finance/affiliations/view/:id",
        component: lazy(() => import("./views/Affiliation/AffiliationShow")),
        title: "Finance|Affiliations-View|Takiacademy",
      },
      {
        exact: true,
        path: "/finance/affiliations-evolution",
        component: lazy(
          () => import("./views/AffiliationEvolution/AffiliationsEvolutionList")
        ),
        title: "Finance|Affiliations-Evolution|Takiacademy",
      },
      {
        exact: true,
        path: "/finance/finance-codes",
        component: lazy(() => import("./views/Code/CodeList")),
        title: "Finance-Codes|Takiacademy",
      },
      {
        exact: true,
        path: "/finance/finance-codes/create",
        component: lazy(() => import("./views/Code/CodeCreate")),
        title: "Finance-Codes-Create|Takiacademy",
      },
      {
        exact: true,
        path: "/finance/finance-codes/edit/:id",
        component: lazy(() => import("./views/Code/CodeEdit")),
        title: "Finance-Codes-Edit|Takiacademy",
      },
      {
        exact: true,
        path: "/finance/finance-codes/view/:id",
        component: lazy(() => import("./views/Code/CodeShow")),
        title: "Finance-Codes-View|Takiacademy",
      },
      {
        exact: true,
        path: "/finance/codes",
        component: lazy(() => import("./views/Code/AutogeneratedCodeList")),
        title: "Finance|Codes|Takiacademy",
      },
      {
        exact: true,
        path: "/finance/d17-orders",
        component: lazy(() => import("./views/D17Orders/D17OrderList")),
        title: "Finance|D17-Ordres|Takiacademy",
      },
      {
        exact: true,
        path: "/finance/poste-payments",
        component: lazy(() => import("./views/PostPayments/PostPaymentList")),
        title: "Finance|Poste|Takiacademy",
      },
      {
        exact: true,
        path: "/finance/Ooredoo-orders",
        component: lazy(
          () => import("./views/OoredooOrders/OoredooOrdersList")
        ),
        title: "Finance|Ooredoo-orders|Takiacademy",
      },
      {
        exact: true,
        path: "/finance/checks",
        component: lazy(() => import("./views/Check/CheckList")),
        title: "Marketing|Checks|Takiacademy",
      },
      {
        exact: true,
        path: "/finance/checks/edit/:id",
        component: lazy(() => import("./views/Check/CheckEdit.js")),
        title: "Marketing|Checks|Takiacademy",
      },
      {
        exact: true,
        path: "/finance/checks/view/:id",
        component: lazy(() => import("./views/Check/CheckShow")),
        title: "Marketing|Checks|Takiacademy",
      },
      {
        exact: true,
        path: "/users/points-conversions",
        component: lazy(
          () => import("./views/PointConversion/PointConversionList")
        ),
        title: "Points-Conversions|Takiacademy",
      },
      {
        exact: true,
        path: "/users/points-conversions/edit/:id",
        component: lazy(
          () => import("./views/PointConversion/PointConversionEdit")
        ),
        title: "Points-Conversions|Takiacademy",
      },
      {
        exact: true,
        path: "/users/point-transfers",
        component: lazy(
          () => import("./views/PointTransaction/PointTransactionList")
        ),
        title: "Points-Transactions|Takiacademy",
      },
      {
        exact: true,
        path: "/users/point-transfers/create",
        component: lazy(
          () => import("./views/PointTransaction/PointTransactionCreate")
        ),
        title: "Points-Transfer-Create|Takiacademy",
      },
      {
        exact: true,
        path: "/finance/buy-for-user",
        component: lazy(() => import("./views/Affiliation/BuyForUserForm")),
        title: "Finance|Buy-For-User|Takiacademy",
      },

      {
        exact: true,
        path: "/offers/offers",
        component: lazy(() => import("./views/Offer/OfferList")),
        title: "Offers-Settings|Offers|Takiacademy",
      },
      {
        exact: true,
        path: "/offers/offers/create",
        component: lazy(() => import("./views/Offer/OfferCreate")),
        title: "Offers-Settings|Offers-Create|Takiacademy",
      },
      {
        exact: true,
        path: "/offers/offers/edit/:id",
        component: lazy(() => import("./views/Offer/OfferEdit")),
        title: "Offers-Settings|Offers-Edit|Takiacademy",
      },
      {
        exact: true,
        path: "/offers/offers/view/:id",
        component: lazy(() => import("./views/Offer/OfferShow")),
        title: "Offers-Settings|Offers|Takiacademy",
      },
      {
        exact: true,
        path: "/offers/offer-special-prices",
        component: lazy(
          () => import("./views/OfferSpecialPrice/OfferSpecialPriceList")
        ),
        title: "Offers-Settings|offer-special-prices|Takiacademy",
      },
      {
        exact: true,
        path: "/offers/offer-special-prices/create",
        component: lazy(
          () => import("./views/OfferSpecialPrice/OfferSpecialPriceCreate")
        ),
        title: "Offers-Settings|offer-special-prices-Create|Takiacademy",
      },
      {
        exact: true,
        path: "/offers/offer-special-prices/edit/:id",
        component: lazy(
          () => import("./views/OfferSpecialPrice/OfferSpecialPriceEdit")
        ),
        title: "Offers-Settings|offer-special-prices-Edit|Takiacademy",
      },
      {
        exact: true,
        path: "/offers/offer-special-prices/view/:id",
        component: lazy(
          () => import("./views/OfferSpecialPrice/OfferSpecialPriceShow")
        ),
        title: "Offers-Settings|offer-special-prices-View|Takiacademy",
      },
      {
        exact: true,
        path: "/offers/offer-group-links",
        component: lazy(() => import("./views/OfferGroup/OfferGroupList")),
        title: "Offers-Settings|Offer-group-links|Takiacademy",
      },
      {
        exact: true,
        path: "/offers/offer-group-links/create",
        component: lazy(() => import("./views/OfferGroup/OfferGroupCreate")),
        title: "Offers-Settings|Offer-group-links-Create|Takiacademy",
      },
      {
        exact: true,
        path: "/offers/offer-group-links/edit/:id",
        component: lazy(() => import("./views/OfferGroup/OfferGroupEdit")),
        title: "Offers-Settings|Offer-group-links-Edit|Takiacademy",
      },
      {
        exact: true,
        path: "/offers/offer-group-links/view/:id",
        component: lazy(() => import("./views/OfferGroup/OfferGroupShow")),
        title: "Offers-Settings|Offer-group-links-View|Takiacademy",
      },
      {
        exact: true,
        path: "/offers/offer-token",
        component: lazy(() => import("./views/OfferToken/OfferTokenList")),
        title: "Offers-Settings|Offer-Token|Takiacademy",
      },
      {
        exact: true,
        path: "/offers/offer-token/create",
        component: lazy(() => import("./views/OfferToken/OfferTokenCreate")),
        title: "Offers-Settings|Offer-Token-Create|Takiacademy",
      },
      {
        exact: true,
        path: "/offers/offer-token/edit/:id",
        component: lazy(() => import("./views/OfferToken/OfferTokenEdit")),
        title: "Offers|Offer-Token-Edit|Takiacademy",
      },
      {
        exact: true,
        path: "/marketing/offices",
        component: lazy(() => import("./views/Office/OfficeList")),
        title: "Marketing|Offices|Takiacademy",
      },
      {
        exact: true,
        path: "/marketing/offices/create",
        component: lazy(() => import("./views/Office/OfficeCreate")),
        title: "Marketing|Offices-Create|Takiacademy",
      },
      {
        exact: true,
        path: "/marketing/offices/edit/:id",
        component: lazy(() => import("./views/Office/OfficeEdit")),
        title: "Marketing|Offices-Edit|Takiacademy",
      },
      {
        exact: true,
        path: "/marketing/messages",
        component: lazy(() => import("./views/Message/MessageList")),
        title: "Marketing|Messages|Takiacademy",
      },
      {
        exact: true,
        path: "/marketing/messages/create",
        component: lazy(() => import("./views/Message/MessageCreate")),
        title: "Marketing|Messages-Create|Takiacademy",
      },
      {
        exact: true,
        path: "/marketing/messages/edit/:id",
        component: lazy(() => import("./views/Message/MessageEdit")),
        title: "Marketing|Messages-Edit|Takiacademy",
      },
      {
        exact: true,
        path: "/marketing/sms",
        component: lazy(() => import("./views/Sms/SmsList")),
        title: "Marketing|SMS|Takiacademy",
      },
      {
        exact: true,
        path: "/marketing/sms/create",
        component: lazy(() => import("./views/Sms/SmsCreate")),
        title: "Marketing|SMS-Create|Takiacademy",
      },
      {
        exact: true,
        path: "/marketing/sms/view/:id",
        component: lazy(() => import("./views/Sms/SmsShow")),
        title: "Marketing|SMS-View|Takiacademy",
      },
      {
        exact: true,
        path: "/marketing/called-user",
        component: lazy(() => import("./views/CalledUser/CalledUserList")),
        title: "Marketing|Called-User|Takiacademy",
      },
      {
        exact: true,
        path: "/marketing/called-user/yesteday",
        component: lazy(
          () => import("./views/CalledUser/YesterdayCalledUserList")
        ),
        title: "Marketing|Called-User-Yesteday|Takiacademy",
      },
      {
        exact: true,
        path: "/marketing/called-user/today",
        component: lazy(() => import("./views/CalledUser/TodayCalledUserList")),
        title: "Marketing|Called-User-Today|Takiacademy",
      },
      {
        exact: true,
        path: "/marketing/called-user/create",
        component: lazy(() => import("./views/CalledUser/CalledUserCreate")),
        title: "Marketing|Called-User-Create|Takiacademy",
      },
      {
        exact: true,
        path: "/marketing/called-user/edit/:id",
        component: lazy(() => import("./views/CalledUser/CalledUserEdit")),
        title: "Marketing|Called-User-Edit|Takiacademy",
      },
      {
        exact: true,
        path: "/contents/content-events",
        component: lazy(() => import("./views/ContentEvent/ContentEventList")),
        title: "Contents|Events|Takiacademy",
      },
      {
        exact: true,
        path: "/contents/content-events/create",
        component: lazy(
          () => import("./views/ContentEvent/ContentEventCreate")
        ),
        title: "Contents|Events-Create|Takiacademy",
      },
      {
        exact: true,
        path: "/contents/content-events/edit/:id",
        component: lazy(() => import("./views/ContentEvent/ContentEventEdit")),
        title: "Contents|Events-Edit|Takiacademy",
      },
      {
        exact: true,
        path: "/contents/content-events/view/:id",
        component: lazy(() => import("./views/ContentEvent/ContentEventShow")),
        title: "Contents|Events-View|Takiacademy",
      },
      {
        exact: true,
        path: "/contents/content-videos",
        component: lazy(() => import("./views/ContentVideo/ContentVideoList")),
        title: "Contents|Videos|Takiacademy",
      },
      {
        exact: true,
        path: "/contents/content-videos/create",
        component: lazy(
          () => import("./views/ContentVideo/ContentVideoCreate")
        ),
        title: "Contents|Videos-Create|Takiacademy",
      },
      {
        exact: true,
        path: "/contents/content-videos/edit/:id",
        component: lazy(() => import("./views/ContentVideo/ContentVideoEdit")),
        title: "Contents|Videos-Edit|Takiacademy",
      },
      {
        exact: true,
        path: "/contents/content-videos/view/:id",
        component: lazy(() => import("./views/ContentVideo/ContentVideoShow")),
        title: "Contents|Videos-View|Takiacademy",
      },
      {
        exact: true,
        path: "/contents/magazines",
        component: lazy(
          () => import("./views/ContentMagazine/ContentMagazineList")
        ),
        title: "Contents|Magazines|Takiacademy",
      },
      {
        exact: true,
        path: "/contents/magazines/create",
        component: lazy(
          () => import("./views/ContentMagazine/ContentMagazineCreate")
        ),
        title: "Contents|Magazines-Create|Takiacademy",
      },
      {
        exact: true,
        path: "/contents/magazines/edit/:id",
        component: lazy(
          () => import("./views/ContentMagazine/ContentMagazineEdit")
        ),
        title: "Contents|Magazines-Edit|Takiacademy",
      },
      {
        exact: true,
        path: "/contents/magazines/view/:id",
        component: lazy(() => import("./views/ContentMagazine/MagazineShow")),
        title: "Contents|Magazines-View|Takiacademy",
      },
      {
        exact: true,
        path: "/contents/content-exams",
        component: lazy(() => import("./views/ContentExam/ContentExamList")),
        title: "Contents|Exams|Takiacademy",
      },
      {
        exact: true,
        path: "/contents/content-exams/create",
        component: lazy(() => import("./views/ContentExam/ContentExamCreate")),
        title: "Contents|Exams-Create|Takiacademy",
      },
      {
        exact: true,
        path: "/contents/content-exams/edit/:id",
        component: lazy(() => import("./views/ContentExam/ContentExamEdit")),
        title: "Contents|Exams-Edit|Takiacademy",
      },
      {
        exact: true,
        path: "/contents/content-exams/view/:id",
        component: lazy(() => import("./views/ContentExam/ExamShow")),
        title: "Contents|Exams-View|Takiacademy",
      },

      {
        exact: true,
        path: "/contents/webinar-accounts",
        component: lazy(
          () => import("./views/WebinarAccount/WebinarAccountList")
        ),
        title: "Contents|Webinar-Accounts|Takiacademy",
      },
      {
        exact: true,
        path: "/contents/webinar-accounts/create",
        component: lazy(
          () => import("./views/WebinarAccount/WebinarAccountCreate")
        ),
        title: "Contents|Webinar-Accounts-Create|Takiacademy",
      },
      {
        exact: true,
        path: "/contents/webinar-accounts/edit/:id",
        component: lazy(
          () => import("./views/WebinarAccount/WebinarAccountEdit")
        ),
        title: "Contents|Webinar-Accounts-Edit|Takiacademy",
      },
      {
        exact: true,
        path: "/contents/webinar-accounts/view/:id",
        component: lazy(
          () => import("./views/WebinarAccount/WebinarAccountShow")
        ),
        title: "Contents|Webinar-Accounts-View|Takiacademy",
      },
      {
        exact: true,
        path: "/contents/content-scopes",
        component: lazy(() => import("./views/ContentScope/ContentScopeList")),
        title: "Contents|Scopes|Takiacademy",
      },
      {
        exact: true,
        path: "/contents/content-scopes/create",
        component: lazy(
          () => import("./views/ContentScope/ContentScopeCreate")
        ),
        title: "Contents|Scopes-Create|Takiacademy",
      },
      {
        exact: true,
        path: "/contents/content-scopes/edit/:id",
        component: lazy(() => import("./views/ContentScope/ContentScopeEdit")),
        title: "Contents|Scopes-Edit|Takiacademy",
      },
      {
        exact: true,
        path: "/contents/tasks",
        component: lazy(() => import("./views/Task/TaskList")),
        title: "Contents|Tasks|Takiacademy",
      },
      {
        exact: true,
        path: "/contents/tasks/create",
        component: lazy(() => import("./views/Task/TaskCreate")),
        title: "Contents|Tasks-Create|Takiacademy",
      },
      {
        exact: true,
        path: "/contents/tasks/edit/:id",
        component: lazy(() => import("./views/Task/TaskEdit")),
        title: "Contents|Tasks-Edit|Takiacademy",
      },
      {
        exact: true,
        path: "/contents/qcm",
        component: lazy(() => import("./views/Qcm/QcmList")),
        title: "Contents|QCM|Takiacademy",
      },
      {
        exact: true,
        path: "/contents/qcm/create",
        component: lazy(() => import("./views/Qcm/QcmCreate")),
        title: "Contents|QCM-Create|Takiacademy",
      },
      {
        exact: true,
        path: "/contents/qcm/edit/:id",
        component: lazy(() => import("./views/Qcm/QcmEdit")),
        title: "Contents|QCM-Edit|Takiacademy",
      },
      {
        exact: true,
        path: "/acl/groups",
        component: lazy(() => import("./views/UserGroup/UserGroupList")),
        title: "Acl-Groups|Takiacademy",
      },
      {
        exact: true,
        path: "/acl/groups/create",
        component: lazy(() => import("./views/UserGroup/UserGroupCreate")),
        title: "Acl-Groups-Create|Takiacademy",
      },
      {
        exact: true,
        path: "/acl/groups/edit/:id",
        component: lazy(() => import("./views/UserGroup/UserGroupEdit")),
        title: "Acl-Groups-Edit|Takiacademy",
      },
      {
        exact: true,
        path: "/contents/qcm/view/:id",
        component: lazy(() => import("./views/Qcm/QcmShow")),
        title: "Contents-QCM-View|Takiacademy",
      },
      {
        exact: true,
        path: "/acl/groups/view/:id",
        component: lazy(() => import("./views/UserGroup/UserGroupShow")),
        title: "Acl-Groups-View|Takiacademy",
      },
      {
        exact: true,
        path: "/acl/access-user-event-token",
        component: lazy(
          () => import("./views/AccessUserEventToken/AccessUserEventTokenList")
        ),
        title: "User-Tokens|Takiacademy",
      },
      {
        exact: true,
        path: "/acl/access-user-event-token/create",
        component: lazy(
          () =>
            import("./views/AccessUserEventToken/AccessUserEventTokenCreate")
        ),
        title: "User-Tokens-Create|Takiacademy",
      },
      {
        exact: true,
        path: "/acl/access-user-event-token/edit/:id",
        component: lazy(
          () => import("./views/AccessUserEventToken/AccessUserEventTokenEdit")
        ),
        title: "User-Tokens-Edit|Takiacademy",
      },
      {
        exact: true,
        path: "/acl/permission-rules",
        component: lazy(
          () => import("./views/PermissionRule/PermissionRuleList")
        ),
        title: "Acl-Permission|Rules|Takiacademy",
      },
      {
        exact: true,
        path: "/acl/permission-rules/create",
        component: lazy(
          () => import("./views/PermissionRule/PermissionRuleCreate")
        ),
        title: "Acl-Permission|Rules-Create|Takiacademy",
      },
      {
        exact: true,
        path: "/acl/permission-rules/edit/:id",
        component: lazy(
          () => import("./views/PermissionRule/PermissionRuleEdit")
        ),
        title: "Acl-Permission|Rules-Edit|Takiacademy",
      },
      {
        exact: true,
        path: "/settings/admin-users",
        component: lazy(() => import("./views/AdminUser/AdminUserList")),
        title: "Settings|Admin-Users|Takiacademy",
      },
      {
        exact: true,
        path: "/settings/admin-users/create",
        component: lazy(() => import("./views/AdminUser/AdminUserCreate")),
        title: "Settings|Admin-Users-Create|Takiacademy",
      },
      {
        exact: true,
        path: "/settings/admin-users/edit/:id",
        component: lazy(() => import("./views/AdminUser/AdminUserEdit")),
        title: "Settings|Admin-Users-Edit|Takiacademy",
      },
      {
        exact: true,
        path: "/settings/roles",
        component: lazy(() => import("./views/AdminRole/AdminRoleList")),
        role: "roles_list",
        title: "Settings|Admin-Roles|Takiacademy",
      },
      {
        exact: true,
        path: "/settings/roles/create",
        component: lazy(() => import("./views/AdminRole/AdminRoleCreate")),
        role: "roles_create",
        title: "Settings|Admin-Roles-Create|Takiacademy",
      },
      {
        exact: true,
        path: "/settings/roles/edit/:id",
        component: lazy(() => import("./views/AdminRole/AdminRoleEdit")),
        role: "roles_edit",
        title: "Settings|Admin-Roles-Edit|Takiacademy",
      },

      {
        exact: true,
        path: "/settings/institutes",
        component: lazy(() => import("./views/Institute/InstituteList")),
        title: "Settings|Institutes|Takiacademy",
      },
      {
        exact: true,
        path: "/settings/institutes/create",
        component: lazy(() => import("./views/Institute/InstituteCreate")),
        title: "Settings|Institutes-Create|Takiacademy",
      },
      {
        exact: true,
        path: "/settings/institutes/edit/:id",
        component: lazy(() => import("./views/Institute/InstituteEdit")),
        title: "Settings|Institutes-Edit|Takiacademy",
      },
      {
        exact: true,
        path: "/settings/delegations",
        component: lazy(() => import("./views/Delegation/DelegationList")),
        title: "Settings|Delegations|Takiacademy",
      },
      {
        exact: true,
        path: "/settings/delegations/create",
        component: lazy(() => import("./views/Delegation/DelegationCreate")),
        title: "Settings|Delegations-Create|Takiacademy",
      },
      {
        exact: true,
        path: "/settings/delegations/edit/:id",
        component: lazy(() => import("./views/Delegation/DelegationEdit")),
        title: "Settings|Delegations-Edit|Takiacademy",
      },
      {
        exact: true,
        path: "/settings/student-levels",
        component: lazy(() => import("./views/Level/LevelList")),
        title: "Settings|Student-Levels|Takiacademy",
      },
      {
        exact: true,
        path: "/settings/student-levels/create",
        component: lazy(() => import("./views/Level/LevelCreate")),
        title: "Settings|Student-Levels-Create|Takiacademy",
      },
      {
        exact: true,
        path: "/settings/student-levels/edit/:id",
        component: lazy(() => import("./views/Level/LevelEdit")),
        title: "Settings|Student-Levels-Edit|Takiacademy",
      },
      {
        exact: true,
        path: "/settings/student-level-configs",
        component: lazy(() => import("./views/LevelConfig/LevelConfigList")),
        title: "Settings|Student-Levels-Config|Takiacademy",
      },
      {
        exact: true,
        path: "/settings/student-level-configs/create",
        component: lazy(() => import("./views/LevelConfig/LevelConfigCreate")),
        title: "Settings|Student-Levels-Config-Create|Takiacademy",
      },
      {
        exact: true,
        path: "/settings/student-level-configs/edit/:id",
        component: lazy(() => import("./views/LevelConfig/LevelConfigEdit")),
        title: "Settings|Student-Levels-Config-Edit|Takiacademy",
      },
      {
        exact: true,
        path: "/user-settings/mark-sheets",
        component: lazy(() => import("./views/MarkSheets/MarkSheetList")),
        title: "User-Settings|MarkSheets|Takiacademy",
      },
      {
        exact: true,
        path: "/user-settings/mark-sheets/create",
        component: lazy(() => import("./views/MarkSheets/MarkSheetCreate")),
        title: "User-Settings|MarkSheets-Create|Takiacademy",
      },
      {
        exact: true,
        path: "/user-settings/mark-sheets/edit/:id",
        component: lazy(() => import("./views/MarkSheets/MarkSheetEdit")),
        title: "User-Settings|MarkSheets-Edit|Takiacademy",
      },
      {
        exact: true,
        path: "/user-settings/mark-sheets/view/:id",
        component: lazy(() => import("./views/MarkSheets/MarkSheetShow")),
        title: "User-Settings|MarkSheets-View|Takiacademy",
      },

      {
        exact: true,
        path: "/user-settings/edit-divisions",
        component: lazy(() => import("./views/DivisionEdit/DivisionEditList")),
        title: "User-Settings|Edit-Divisions|Takiacademy",
      },
      {
        exact: true,
        path: "/user-settings/edit-divisions/edit/:id",
        component: lazy(() => import("./views/DivisionEdit/DivisionEditEdit")),
        title: "User-Settings|Edit-Divisions-Edit|Takiacademy",
      },
      {
        exact: true,
        path: "/user-settings/edit-divisions/view/:id",
        component: lazy(() => import("./views/DivisionEdit/DivisionEditShow")),
        title: "User-Settings|Edit-Divisions-View|Takiacademy",
      },
      {
        exact: true,
        path: "/user-settings/student-level-user",
        component: lazy(
          () => import("./views/StudentLevelUser/StudentLevelUserList")
        ),
        title: "User-Settings|Student-Level-User|Takiacademy",
      },
      {
        exact: true,
        path: "/user-settings/student-level-user/create",
        component: lazy(
          () => import("./views/StudentLevelUser/StudentLevelUserCreate")
        ),
        title: "User-Settings|Student-Level-User-Create|Takiacademy",
      },
      {
        exact: true,
        path: "/user-settings/student-level-user/edit/:id",
        component: lazy(
          () => import("./views/StudentLevelUser/StudentLevelUserEdit")
        ),
        title: "User-Settings|Student-Level-User-Edit|Takiacademy",
      },
      {
        exact: true,
        path: "/teacher-settings/penalties",
        component: lazy(() => import("./views/Penalty/PenaltyList")),
        title: "Teacher-Settings|Penalty|Takiacademy",
      },
      {
        exact: true,
        path: "/teacher-settings/penalties/create",
        component: lazy(() => import("./views/Penalty/PenaltyCreate")),
        title: "Teacher-Settings|Penalty-Create|Takiacademy",
      },
      {
        exact: true,
        path: "/teacher-settings/penalties/edit/:id",
        component: lazy(() => import("./views/Penalty/PenaltyEdit")),
        title: "Teacher-Settings|Penalty-Edit|Takiacademy",
      },
      {
        exact: true,
        path: "/teacher-settings/penalty-details",
        component: lazy(
          () => import("./views/PenaltyDetails/PenaltyDetailsList")
        ),
        title: "Teacher-Settings|Penalty-Details|Takiacademy",
      },
      {
        exact: true,
        path: "/teacher-settings/penalty-details/create",
        component: lazy(
          () => import("./views/PenaltyDetails/PenaltyDetailsCreate")
        ),
        title: "Teacher-Settings|Penalty-Details-Create|Takiacademy",
      },
      {
        exact: true,
        path: "/teacher-settings/penalty-details/edit/:id",
        component: lazy(
          () => import("./views/PenaltyDetails/PenaltyDetailsEdit")
        ),
        title: "Teacher-Settings|Penalty-Details-Edit|Takiacademy",
      },
      {
        exact: true,
        path: "/teacher-settings/penalty-units",
        component: lazy(() => import("./views/PenaltyUnit/PenaltyUnitList")),
        title: "Teacher-Settings|Penalty-Units|Takiacademy",
      },
      {
        exact: true,
        path: "/teacher-settings/penalty-units/create",
        component: lazy(() => import("./views/PenaltyUnit/PenaltyUnitCreate")),
        title: "Teacher-Settings|Penalty-Units-Create|Takiacademy",
      },
      {
        exact: true,
        path: "/teacher-settings/penalty-units/edit/:id",
        component: lazy(() => import("./views/PenaltyUnit/PenaltyUnitEdit")),
        title: "Teacher-Settings|Penalty-Units-Edit|Takiacademy",
      },
      {
        exact: true,
        path: "/teacher-settings/event-penalties",
        component: lazy(() => import("./views/EventPenalty/EventPenaltyList")),
        title: "Teacher-Settings|Events-Penalties|Takiacademy",
      },
      {
        exact: true,
        path: "/contents/video-reports",
        component: lazy(() => import("./views/VideoReport/VideoReportList")),
        title: "Contents-Video|reports|Takiacademy",
      },
      {
        exact: true,
        path: "/contents/video-reports/edit/:id",
        component: lazy(() => import("./views/VideoReport/VideoReportEdit")),
        title: "Contents-Video|Reports-Edit|Takiacademy",
      },
      {
        exact: true,
        path: "/settings/video-reports-reasons",
        component: lazy(
          () => import("./views/VideoReportReasons/VideoReportReasonList")
        ),
        title: "Settings-Report|Reasons|Takiacademy",
      },
      {
        exact: true,
        path: "/settings/video-reports-reasons/edit/:id",
        component: lazy(
          () => import("./views/VideoReportReasons/VideoReportReasonEdit")
        ),
        title: "Settings-Report|Reasons-Edit|Takiacademy",
      },
      {
        exact: true,
        path: "/settings/video-reports-reasons/create",
        component: lazy(
          () => import("./views/VideoReportReasons/VideoReportReasonCreate")
        ),
        title: "Settings-Report|Reasons-Create|Takiacademy",
      },
      {
        exact: true,
        path: "/user-settings/banned-users",
        component: lazy(() => import("./views/BannedUser/BannedUserList")),
        title: "User|Settings-Banned-Users|Takiacademy",
      },

      {
        exact: true,
        path: "/user-settings/banned-users/create",
        component: lazy(() => import("./views/BannedUser/BannedUserCreate")),
        title: "User|Settings-Banned-Users-Create|Takiacademy",
      },
      {
        exact: true,
        path: "/user-settings/banned-users/edit/:id",
        component: lazy(() => import("./views/BannedUser/BannedUserEdit")),
        title: "User|Settings-Banned-Users-Edit|Takiacademy",
      },
      {
        exact: true,
        path: "/user-settings/segments",
        component: lazy(() => import("./views/Segmentation/SegmentationList")),
        title: "User|Settings-Segments|Takiacademy",
      },
      {
        exact: true,
        path: "/user-settings/segments/create",
        component: lazy(
          () => import("./views/Segmentation/SegmentationCreate")
        ),
        title: "User|Settings-Segments-Create|Takiacademy",
      },
      {
        exact: true,
        path: "/user-settings/segments/edit/:id",
        component: lazy(() => import("./views/Segmentation/SegmentationEdit")),
        title: "User|Settings-Segments-Edit|Takiacademy",
      },
      {
        exact: true,
        path: "/user-settings/segments/view/:id",
        component: lazy(() => import("./views/Segmentation/SegmentationShow")),
        title: "User|Settings-Segments-View|Takiacademy",
      },
      {
        exact: true,
        path: "/settings/help-videos",
        component: lazy(() => import("./views/HelpVideo/HelpVideoList")),
        title: "Settings-Help|Videos|Takiacademy",
      },
      {
        exact: true,
        path: "/settings/help-videos/create",
        component: lazy(() => import("./views/HelpVideo/HelpVideoCreate")),
        title: "Settings-Help|Videos-Create|Takiacademy",
      },
      {
        exact: true,
        path: "/settings/help-videos/edit/:id",
        component: lazy(() => import("./views/HelpVideo/HelpVideoEdit")),
        title: "Settings-Help|Videos-Edit|Takiacademy",
      },
      {
        exact: true,
        path: "/finance/company-accounts",
        component: lazy(
          () => import("./views/CompanyAccount/CompanyAccountList")
        ),
        title: "Marketing|Company|Accounts|Takiacademy",
      },
      {
        exact: true,
        path: "/finance/company-accounts/create",
        component: lazy(
          () => import("./views/CompanyAccount/CompanyAccountCreate")
        ),
        title: "Marketing|Company|Accounts-Create|Takiacademy",
      },
      {
        exact: true,
        path: "/finance/company-accounts/edit/:id",
        component: lazy(
          () => import("./views/CompanyAccount/CompanyAccountEdit")
        ),
        title: "Marketing|Company|Accounts-Edit|Takiacademy",
      },

      {
        exact: true,
        path: "/marketing/pubs-cards",
        component: lazy(() => import("./views/OfferCards/OfferCards")),
        title: "Marketing|Pub-Cards|Takiacademy",
      },
      {
        exact: true,
        path: "/marketing/pubs-cards/create",
        component: lazy(() => import("./views/OfferCards/OfferCardCreate")),
        title: "Marketing|Pub-Cards-CREATE|Takiacademy",
      },
      {
        exact: true,
        path: "/marketing/pubs-cards/edit/:id",
        component: lazy(() => import("./views/OfferCards/OfferCardEdit")),
        title: "Marketing|Pub-Cards-EDIT|Takiacademy",
      },
      {
        exact: true,
        path: "/marketing/pubs-cards/view/:id",
        component: lazy(() => import("./views/OfferCards/OfferCardsShow")),
        title: "Marketing|Pub-Cards-View|Takiacademy",
      },
      {
        exact: true,
        path: "/gift/gift",
        component: lazy(() => import("./views/Gift/GiftList")),
        title: "Gift|Gifts|Takiacademy",
      },
      {
        exact: true,
        path: "/gift/gift/create",
        component: lazy(() => import("./views/Gift/GiftCreate")),
        title: "Gift|Gift-CREATE|Takiacademy",
      },
      {
        exact: true,
        path: "/gift/gift/edit/:id",
        component: lazy(() => import("./views/Gift/GiftEdit")),
        title: "Gift|Gift-EDIT|Takiacademy",
      },
      {
        exact: true,
        path: "/gift/gift/view/:id",
        component: lazy(() => import("./views/Gift/GiftShow")),
        title: "Gift|Gift-View|Takiacademy",
      },
      //---
      {
        exact: true,
        path: "/gift/gift-request",
        component: lazy(() => import("./views/GiftRequest/GiftRequestList")),
        title: "Gift|Gifts|Takiacademy",
      },

      {
        exact: true,
        path: "/gift/gift-request/edit/:id",
        component: lazy(() => import("./views/GiftRequest/GiftRequestEdit")),
        title: "Gift|Gift-EDIT|Takiacademy",
      },
      {
        exact: true,
        path: "/gift/gift-request/view/:id",
        component: lazy(() => import("./views/GiftRequest/GiftRequestShow")),
        title: "Gift|Gift-View|Takiacademy",
      },
      {
        exact: true,
        path: "/archives/chapters",
        component: lazy(() => import("./views/Archives/ChapterArchiveList")),
        title: "Archives-Chapters|Takiacademy",
      },
      {
        exact: true,
        path: "/archives/subjects",
        component: lazy(() => import("./views/Archives/SubjectArchiveList")),
        title: "Archives-Subjects|Takiacademy",
      },
      {
        exact: true,
        path: "/archives/codes",
        component: lazy(() => import("./views/Archives/CodeArchiveList")),
        title: "Archives-Codes|Takiacademy",
      },
      {
        exact: true,
        path: "/archives/payment-agreements",
        component: lazy(
          () => import("./views/Archives/PaymentAgreementArchiveList")
        ),
        title: "Archives-Payment-Agreements|Takiacademy",
      },
      {
        exact: true,
        path: "/archives/contents",
        component: lazy(() => import("./views/Archives/ContentArchiveList")),
        title: "Archives-Contents|Takiacademy",
      },
      {
        exact: true,
        path: "/archives/affiliations",
        component: lazy(
          () => import("./views/Archives/AffiliationArchiveList")
        ),
        title: "Archives-Affiliations|Takiacademy",
      },
      {
        exact: true,
        path: "/archives/users",
        component: lazy(() => import("./views/Archives/UserArchiveList")),
        title: "Archives-Users|Takiacademy",
      },
      {
        exact: true,
        path: "/hessaty/traineeships",
        component: lazy(() => import("./views/Traineeship/TraineeshipList")),
        title: "Hessaty-Traineeships|Takiacademy",
      },
      {
        exact: true,
        path: "/hessaty/traineeships/create",
        component: lazy(() => import("./views/Traineeship/TraineeshipCreate")),
        title: "Hessaty|Traineeships-Create|Takiacademy",
      },
      {
        exact: true,
        path: "/hessaty/traineeships/edit/:id",
        component: lazy(() => import("./views/Traineeship/TraineeshipEdit")),
        title: "Hessaty|Traineeships-Edit|Takiacademy",
      },
      {
        exact: true,
        path: "/hessaty/traineeships/view/:id",
        component: lazy(() => import("./views/Traineeship/TraineeshipShow")),
        title: "Hessaty|Traineeships-View|Takiacademy",
      },
      {
        exact: true,
        path: "/hessaty/content-events",
        component: lazy(
          () => import("./views/TraineeshipEvent/TraineeshipEventList")
        ),
        title: "Hessaty|Content-Events|Takiacademy",
      },
      {
        exact: true,
        path: "/hessaty/content-events/create",
        component: lazy(
          () => import("./views/TraineeshipEvent/TraineeshipEventCreate")
        ),
        title: "Hessaty|Content-Events-Create|Takiacademy",
      },
      {
        exact: true,
        path: "/hessaty/content-events/edit/:id",
        component: lazy(
          () => import("./views/TraineeshipEvent/TraineeshipEventEdit")
        ),
        title: "Hessaty|Content-Events-Edit|Takiacademy",
      },
      {
        exact: true,
        path: "/hessaty/content-events/view/:id",
        component: lazy(
          () => import("./views/TraineeshipEvent/TraineeshipEventShow")
        ),
        title: "Hessaty|Content-Events-View|Takiacademy",
      },
      {
        exact: true,
        path: "/dashboard",
        component: () => <Redirect to="/" />,
        title: "Dashboard|Takiacademy",
      },
      {
        exact: true,
        path: "/notifications/popups",
        component: lazy(() => import("./views/Popups/PopupsList")),
        title: "Notifications|Popups|Takiacademy",
      },
      {
        exact: true,
        path: "/notifications/popups/create",
        component: lazy(() => import("./views/Popups/PopupsCreate")),
        title: "Notifications|Popups-Create|Takiacademy",
      },
      {
        exact: true,
        path: "/notifications/popups/edit/:id",
        component: lazy(() => import("./views/Popups/PopupsEdit")),
        title: "Notifications|Popups-Edit|Takiacademy",
      },
      {
        exact: true,
        path: "/finance/config",
        component: lazy(() => import("./views/AdminConfig/AdminConfigList")),
        title: "Finance-Config|Takiacademy",
      },
      {
        exact: true,
        path: "/finance/config/create",
        component: lazy(() => import("./views/AdminConfig/AdminConfigCreate")),
        title: "Finance-Config-Create|Takiacademy",
      },
      {
        exact: true,
        path: "/finance/config/edit/:id",
        component: lazy(() => import("./views/AdminConfig/AdminConfigEdit")),
        title: "Finance-Config-Edit|Takiacademy",
      },
    ],
  },
  {
    path: "*",
    layout: MinimalLayout,
    routes: [
      // {
      //   exact: true,
      //   path: '/',
      //   component: HomeView
      // },
      {
        component: () => <Redirect to="/404" />,
        title: "Not-found|Takiacademy",
      },
    ],
  },
];

export default routes;
